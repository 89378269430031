<template>
    <div class='elevator'>
        <!--       电梯管理-->
        <div class="filter-panel">
            <input
                    type="number"
                    class="cs-input"
                    v-model="queryParams.keyword"
                    style="width: 220px"
                    placeholder="搜索电梯ID"
            />

            <CSSelect>
                <select v-model="queryParams.buildingId" style="width: 130px">
                    <option value="">全部楼栋</option>
                    <option
                            v-for="val in buildingList"
                            :key="val.id"
                            :value="val.id"
                    >
                        {{ val.name }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect>
                <select v-model="queryParams.locationId" style="width: 130px">
                    <option value="">全部位置</option>
                    <option
                            v-for="val in adderssList"
                            :key="val.id"
                            :value="val.id"
                    >
                        {{ val.specificLocation }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect>
                <select v-model="queryParams.state">
                    <option value="">梯控状态不限</option>
                    <option
                            v-for="(val, type) in INDUSTRY_TYPES"
                            :key="type.value"
                            :value="val.id"
                    >
                        {{ val.value }}
                    </option>
                </select>
            </CSSelect>
            <button
                    class="btn btn-primary"
                    style="margin-left: 10px"
                    @click="searchData()"
            >
                查询
            </button>
        </div>
        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel text-right">

                        <button
                                class="btn btn-primary sticky-right"
                                @click="addElevator"
                        >
                            <svg class="icon" aria-hidden="true" style="font-size: 14px">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            添加电梯
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>电梯ID</th>
                        <th>关联楼栋</th>
                        <th>电梯位置</th>
                        <th>梯控绑定二维码</th>
                        <th>梯控状态</th>
                        <th>备注</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in  dataList" :key="item.id">
                        <td>{{item.createTime}}</td>
                        <td>{{item.id}}</td>
                        <td>{{item.building}}</td>
                        <td>{{item.location}}</td>
                        <td><span @click="panelDialogVisibleBtn(item)" class="erweimaC">查看</span></td>
                        <td>{{item.state == 1?'关闭':'开启'}}</td>
                        <td><span @click="beiDialogVisibleBtn(item.note)" :class="[item.note?'erweimaC':'']">{{item.note?'查看':'-'}}</span>
                        </td>
                        <td>
                            <div class="btn-group">
                                <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none"
                                                @click="modifyBtn(item)"
                                        >
                                            修改
                                        </a>
                                        <a
                                                style="width: 100%; text-decoration: none"
                                                @click="deleteBtn(item.id)"
                                        >
                                            删除
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>

                    </tr>
                </template>
            </CSTable>
            <Pagination name="pagination"
                        componentName="Pagination"></Pagination>
            <!--            添加或修改电梯-->
            <CSDialog
                    dialog-width="600px"
                    :dialog-title="addRobotDialogTitle"
                    :dialog-visible="addRobotVisible"
                    @onConfirm="addElevarot()"
                    @onClose="addRobotVisible = false"
            >
                <template v-slot:dialog-content>
                    <div class="cs-item" style="margin-top: 30px">
                        <div class="item-lable">关联楼栋</div>
                        <div>
                            <CSSelect iWidth="36px" style="margin-right: 20px;width: 402px" height="40px">
                                <select
                                        v-model="fromElevator.building"
                                        @change="changeCompany"
                                        style="padding-left: 5px; width: 200px;color: #999999;font-size: 24px"
                                >
                                    <option value="">请选择</option>
                                    <option :value="item.id" v-for="item in  buildingList" :key="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </CSSelect>
                        </div>
                    </div>
                    <div class="cs-item">
                        <div class="item-lable">电梯位置</div>
                        <div>
                            <ChooseLocation @changeLocation="changeLocation"
                                            :defaultLocationName="fromElevator.adderss"/>
                        </div>
                    </div>
                    <div class="cs-item">
                        <div class="item-lable">梯控状态</div>
                        <CSRadio
                                style="font-size: 24px"
                                inner-width="14px"
                                width="20px"
                                v-model="fromElevator.entranceRestrict"
                                :items="{ 0: '开启', 1: '关闭' }"

                        />
                    </div>
                    <div class="cs-item">
                        <div class="item-lable" style="margin-bottom: 180px">备注</div>
                        <div>
                            <textarea
                                    class="el-input"
                                    style="resize:none;color: black;border:none"
                                    placeholder="限200个字，非必填"
                                    v-model="fromElevator.textarea"
                                    maxlength="200"
                            >
                            </textarea>
                        </div>
                    </div>
                </template>
            </CSDialog>

            <!--            查看图片-->
            <CSDialog
                    :dialogVisible="panelDialogVisible"
                    dialogTitle="查看梯控绑定二维码"
                    dialogWidth="380px"
                    dialog-header-class="alert-bg"
                    dialogCancelBtnText="关闭"
                    :dialogShowConfirmBtn="false"
                    @onClose="panelDialogVisible = false"
            >
                <div
                        slot="dialog-content"
                        style="padding: 30px; font-size: 24px; text-align: center;"
                >
                    <div ref="qrCodeUrl" style="margin: 1px auto 0 auto; width: 320px; height: 320px"></div>
                    <div style="display: flex;padding-top: 20px">
                        <svg
                                class="icon"
                                aria-hidden="true"
                                style="font-size: 20px; margin-right: 10px;margin-top: 4px"
                        >
                            <use xlink:href="#icon-menua-zu92"></use>
                        </svg>

                        <div style="font-size: 20px;color: #999999;text-align: left;display: inline-block;width: 290px"
                        >请使用该二维码在梯控重启后60 秒内刷码绑定梯控设备。
                        </div
                        >
                    </div>
                </div>
            </CSDialog>
            <!--            查看备注-->
            <CSDialog
                    :dialogVisible=" beiDialogVisible"
                    dialogTitle="备注"
                    dialogWidth="430px"
                    dialog-header-class="alert-bg"
                    dialogCancelBtnText="关闭"
                    :dialogShowConfirmBtn="false"
                    @onClose=" beiDialogVisible = false"
            >
                <div
                        slot="dialog-content"
                        style="padding: 30px; font-size: 24px; text-align: center"
                >
                    {{ beiDialogVisiTitle }}
                </div>
            </CSDialog>
        </div>
    </div>
</template>

<script>
    import CSSelect from "@/components/common/CSSelect";
    import CSTable from "@/components/common/CSTable";
    import CSDialog from "@/components/common/CSDialog";
    import ChooseLocation from "@/components/ChooseLocation";
    import CSRadio from "@/components/common/CSRadio";
    import {
        envQueryLocationUrl,
        queryBuildingUrl,
        querAllElevatorUrl,
        addElevatorUrl,
        deleteElevatorUrl,
        editElevatorUrl,
        getElevatorQrCodeUrl
    } from "@/requestUrl";
    import TreePanel from "@/components/common/TreePanel";
    import CSTree from "@/components/common/CSTree";
    import Pagination from "@/components/Pagination";
    import QRCode from "qrcodejs2";

    export default {
        name: "elevator",
        data() {
            return {
                qrImage: '',
                beiDialogVisiTitle: '',
                dataList: [],
                treePanelVisible: false,//是否显示
                taskVisible: false,
                filterHeight: 0,
                INDUSTRY_TYPES: [{id: 0, value: '开启'}, {id: 1, value: '关闭'}],
                panelDialogVisible: false,//查看二维码
                beiDialogVisible: false,//查看备注
                addRobotVisible: false,//'添加或修改电梯',
                addRobotDialogTitle: '添加电梯',
                queryParams: {
                    keyword: '',//搜索关键字
                    industry: '全部楼栋',//梯控状态
                    address: '全部位置',
                    state: '',
                    buildingId: "",
                    locationId: ""

                },
                fromElevator: {
                    id: '',
                    building: '',//关联楼栋
                    entranceRestrict: 0,//梯控状态
                    textarea: "",//备注
                    adderss: '',//电梯位置
                    locationId: ''//位置ID
                },
                adderssList: [],//所有位置
                selectAdderssList: [],//选中的位置
                buildingList: [],//楼栋列表
                selectBuildingList: []//选中的楼栋
            }
        },
        methods: {
            //获取楼层乘梯二维码
            getElevatorQrCode(item) {
                return this.$fly.post(getElevatorQrCodeUrl, {
                    "regionCode": this.$vc.getCurrentRegion().code, //园区code  必填
                    "buildingId": item.buildingId, //楼栋ID  列表获取二维码必填
                    "floor": null,  //楼层  列表获取二维码必填
                    "userId": this.$vc.getCurrentStaffInfo().userId, //用户ID  必填
                    "type": 1,  //类型 0-小程序 1-后台
                    "isShare":1,
                    "shareTime":''
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    
                    // 创建qrcode对象
                    let qrcode = new QRCode(this.$refs.qrCodeUrl, {
                        //  设置二维码内容 前面有http | https 的扫描会自动跳转
                        text: res.data,
                        //  设置大小
                        width: 320,
                        height: 320,
                        //  定义颜色
                        colorDark: "#000000",
                        colorLight: "#ffffff",
                        correctLevel: QRCode.CorrectLevel.H
                    });
                    this.qrImage = res.data;
                })
            },
            queryInspectTaskKeyDown(e) {
                if (e.keyCode == 13) {
                    this.searchData();
                }
            },
            onChangeDing(res) {
                //楼栋位置
                let {key, source} = res;
                if (!source[key].checked) {
                    source[key].checked = true;
                } else {
                    source[key].checked = false;
                }
                this.selectBuildingList = source.filter(res => {
                    return res.checked;
                })
                console.log('选中的楼栋');
                console.log(this.selectBuildingList);
                this.queryParams.industry = this.selectBuildingList.map(res => res.name).toString();
                this.queryParams.buildingId = this.selectBuildingList.map(item => item.id);
                console.log(this.queryParams.buildingId);
            },
            //楼栋位置变化
            onChangeRooms(res) {
                let {key, source} = res;
                if (!source[key].checked) {
                    source[key].checked = true;
                } else {
                    source[key].checked = false;
                }

                this.selectAdderssList = source.filter(res => {
                    return res.checked;
                })
                console.log('选中的电梯位置');
                console.log(this.selectAdderssList);
                this.queryParams.address = this.selectAdderssList.map(res => {
                    return res.specificLocation
                }).toString();
                this.queryParams.locationId = this.selectAdderssList.map(res => res.id);
                console.log('选中的电梯位置');
                console.log(this.queryParams.locationId);

            },
            //获取楼栋
            getInspectionPoint() {
                this.$fly.post(queryBuildingUrl, {
                    state: 1,
                    regionCode: this.$vc.getCurrentRegion().code,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.buildingList = res.data.datas;
                        console.log("楼栋=",this.buildingList)
                    })
            },
            //选择管理楼栋
            changeCompany() {

            },
            //上传电梯
            addElevarot() {

                this.addEleva();
            },
            //修改
            modifyBtn(item) {
                console.log(item);
                this.fromElevator.building = item.buildingId;
                this.fromElevator.adderss = item.location;
                this.fromElevator.entranceRestrict = item.state;
                this.fromElevator.textarea = item.note;
                this.fromElevator.id = item.id;
                this.fromElevator.locationId = item.locationId
                this.addRobotDialogTitle = '修改电梯'

                this.addRobotVisible = true;
            },
            //添加电梯
            addEleva() {
                let addUrl = addElevatorUrl;
                if (this.fromElevator.id) {
                    addUrl = editElevatorUrl;
                    this.$fly.put(addUrl, {
                        id: this.fromElevator.id, // 添加时为空 不填
                        regionCode: this.$vc.getCurrentRegion().code, // 园区code 必填
                        "buildingId": this.fromElevator.building,  //楼栋ID   必填
                        "locationId": this.fromElevator.locationId,  //位置ID  必填
                        "state": this.fromElevator.entranceRestrict, // 0-开启  1-关闭  必填
                        "note": this.fromElevator.textarea //非必填
                    }).then(res => {
                        if (res.code != 0) {
                            return
                        }
                        if (this.fromElevator.id) {
                            this.$vc.toast('修改成功');
                        } else {
                            this.$vc.toast('添加成功');
                        }

                        this.searchData();
                        this.addRobotVisible = false;
                    })
                } else {
                    this.$fly.post(addUrl, {
                        id: this.fromElevator.id, // 添加时为空 不填
                        regionCode: this.$vc.getCurrentRegion().code, // 园区code 必填
                        "buildingId": this.fromElevator.building,  //楼栋ID   必填
                        "locationId": this.fromElevator.locationId,  //位置ID  必填
                        "state": this.fromElevator.entranceRestrict, // 0-开启  1-关闭  必填
                        "note": this.fromElevator.textarea //非必填
                    }).then(res => {
                        if (res.code != 0) {
                            return
                        }
                        if (this.fromElevator.id) {
                            this.$vc.toast('修改成功');
                        } else {
                            this.$vc.toast('添加成功');
                        }

                        this.searchData();
                        this.addRobotVisible = false;
                    })
                }

            },
            //查看二维码
            async panelDialogVisibleBtn(item) {
                this.panelDialogVisible = true;
                await this.getElevatorQrCode(item);

            },
            //查看备注
            beiDialogVisibleBtn(item) {
                if (!item) {
                    return
                }
                this.beiDialogVisiTitle = item;
                this.beiDialogVisible = true;
            },
            //添加电梯
            addElevator() {
                this.fromElevator.building = '';
                this.fromElevator.adderss = '';
                this.fromElevator.entranceRestrict = 0;
                this.fromElevator.textarea = '';
                this.fromElevator.id = '';
                this.fromElevator.locationId = '';
                this.addRobotVisible = true;
                this.addRobotDialogTitle = '添加电梯'
            },
            //搜索
            searchData(pageSize = 1, pageNum = 10) {
                this.$fly.post(querAllElevatorUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    "elevatorId": this.queryParams.keyword,  // 电梯ID
                    "buildingId": this.queryParams.buildingId == "" ? [] : [this.queryParams.buildingId], //楼栋ID  数组
                    "locationId": this.queryParams.locationId == "" ? [] : [this.queryParams.locationId],//  位置ID  数组
                    "state": this.queryParams.state, // 0-开启  1-关闭
                    pageSize, //页数  必填
                    pageNum //每页条数  必填
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    if (pageSize === 1) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            currentPage: pageSize,
                            total: res.data.total,
                            pageSize: pageNum,
                        });
                    }
                    this.dataList = res.data.datas;
                    console.log(this.dataList);
                    console.log(res);
                })
            },

            //删除
            deleteBtn(id) {
                this.$CSDialog.confirm({
                    title: '提示',
                    message: '确定删除吗？',
                    onConfirm: () => {
                        this.$fly.delete(`${deleteElevatorUrl}/${id}`).then(res => {
                            if (res.code != 0) {
                                return
                            }
                            this.$vc.toast('删除成功');
                            this.searchData();
                            this.$CSDialog.instance.closeDialog();
                        })
                    }
                })
            },
            //选择位置
            changeLocation(res) {
                this.fromElevator.locationId = res.id;
            },
            //清除下拉框
            clearBtn() {
                this.treePanelVisible = false;
                this.taskVisible = false;
            },
            //查询所有的电梯位置
            queryTaskAdderss() {
                this.$fly.post(envQueryLocationUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    specificLocation: ""
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }

                    this.adderssList = res.data;
                    console.log(this.adderssList);
                })
            }
        },
        components: {
            CSSelect,
            CSTable,
            CSDialog,
            ChooseLocation,
            CSRadio,
            TreePanel,
            CSTree,
            Pagination
        },
        mounted() {
            this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
                this.searchData(_currentPage);
            });
            this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            window.addEventListener("resize", () => {
                this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            });
            document.body.addEventListener('click', this.clearBtn)
        },
        beforeDestroy() {
            document.body.removeEventListener('click', this.clearBtn)
            window.removeEventListener("keydown", this.queryInspectTaskKeyDown);
        },
        deactivated() {
            window.removeEventListener("keydown", this.queryInspectTaskKeyDown);
        },
        created() {
            this.searchData();
            this.queryTaskAdderss();
            this.getInspectionPoint();
            window.addEventListener("keydown", this.queryInspectTaskKeyDown);
        }
    }
</script>

<style scoped>
    .cs-item {
        display: flex;
        align-items: center;
        margin-bottom: 23px;

    }

    .erweimaC {
        color: #1DAFFF;
        cursor: pointer;
        text-decoration: underline;
    }

    .item-lable {
        width: 126px;
        height: 33px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 33px;
        color: #000000;
        opacity: 1;
        text-align: right;
        margin-right: 40px;

    }

    .el-input {
        width: 400px;
        height: 200px;
        background: rgba(240, 240, 240, 1);
        opacity: 1;
        border-radius: 5px;
        padding: 10px 15px;
        color: #999999;
        font-size: 24px;
    }

    .cs-selecy {
        position: relative;
        display: inline-block;
    }

    .tree-source-panel {
        position: absolute;
        width: 100px !important;
      display: inline-block;
       
    }


    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }
    input[type="number"]{-moz-appearance:textfield;}
</style>


